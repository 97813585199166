
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SelectBox from '../../components/formComponents/selectBox';
import PageLoader from '../../components/loaders/pageLoader';
import NoData from '../../components/warning/noData';
import Toast from '../../components/toast/toast.js';
import PrimaryButton from '../../components/buttons/primaryButton';

/*Static Images*/
import admin from '../../assets/images/icon/admin.svg';
import location from '../../assets/images/icon/location.svg';
import category from '../../assets/images/icon/category.svg';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';
import APIGet from '../../services/hooks/get';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const array=[
      {icon: admin, title: "BRANCH ADMINS", number: 100 },
      {icon: location, title: "LOCATIONS", number: 20 },
      {icon: category, title: "CATEGORIES", number: 28 }
    ];

    return (
      <React.Fragment>
        <div className="e-main-content e-location">
          <div className="e-border-bottom">
            <div className="row mt-3 ">
              <div className="col-lg-12 pb-2">
                <h2 className="pt-2">Overview</h2>
              </div>
            </div>
          </div>
          <div className="row py-3">
            {
              array.map(data=>(
                <div className="col-lg-4">
              <div className="e-invitation-wrap p-4 e-dashboard-card">
                <div className="row">
                  <div className="col-lg-12">
                      <span className="d-flex align-items-center pb-3"><img src={data.icon} alt="icon"/><h6 className="m-0 px-2">{data.title}</h6></span>
                      <h4 className="m-0">{data.number}</h4>
                  </div>
                  <div className="col-lg-12 ">
                    <div className="float-right pt-3">
                      <PrimaryButton className="e-p-btn e-login-btn float-right" toggle="" target="" name="View Details" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
              ))
            }
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default Dashboard;
