import React, { Component }  from 'react';

class PrimaryButton extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
}
  render() {
  	return(
  		<button type="button" className={this.props.className} disabled={this.props.disabled} data-toggle={this.props.toggle} data-target={this.props.target} onClick={this.props.handleClick}>{this.props.name}</button>
  	);
  }
}

export default PrimaryButton;

