/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/formComponents/inputBox.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../../components/loaders/buttonLoader.js';



/*Assets*/


/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';
import APIGet from '../../services/hooks/get';
import PhoneValidation from '../../services/validation/phone';



class InviteAdminModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      phn: "",
      name: "",
      city: null ,
      apiLoading: false,
      error: ""
    }
  }

  async addBranchAdmin(){
    this.setState({apiLoading: true, error: ""});

    var tempValid = await PhoneValidation(this.state.phn);
    if(tempValid){
      var url = devconfig.admin + "/locationadmin/invite";
      var data = JSON.stringify({
        "name": this.state.name,
        "city_id": this.state.city,
        "mobile": "+91"+this.state.phn
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          $('#inviteAdminModal').modal('hide');
          this.props.success();
          this.props.modalClose();
          this.setState({apiLoading: false});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this))
    }
    else{
      this.setState({ error: "Add a valid mobile number.", apiLoading: false});
    }
  }


  componentDidMount() {
  }


  render() {

    return (
      <>
      {
        this.props.modalOn?
        <div className="modal fade e-ad-detail-wrap e-invite-admin" id="inviteAdminModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8 pl-0">
                <div className="modal-header">
                  <h6 className="e-modal-header">Invite Admin</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.modalClose()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <div className="pt-3">
              <dl className="ev-input-content-wrap">
                <InputBox label="Phone Number" valuechange={(phone) => this.setState({phn: phone})}  prefix="+91" required/>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Name of the Admin / Franchise" valuechange={(name) => this.setState({name: name})} required/>
                </dd>
              </dl>
            </div>
            <div>
              <div className="e-filter-wrap pt-2">
                <SelectBox  placeholder="Choose City" options={this.props.city_List} selectchange={(cityID) => this.setState({city: cityID.value})}/>
              </div>
            </div>
            <div className="row pt-4">
                { this.state.error.length ?  <div className="col-lg-12 pt-1"><div className="e-error mb-3">{this.state.error}</div></div>: null }
                {
                  this.state.apiLoading?
                  <div className="col-lg-12 pt-1">
                  <div className="float-right">
                        <ButtonLoader/>
                      </div>
                  </div>
                  :
                    <React.Fragment>
                      <div className="col-lg-9 col-9 pt-1">
                      <a href="#" className="float-right pt-3 e-modalcancel" data-dismiss="modal" onClick={()=>this.props.modalClose()}>Cancel</a>
                    </div>
                    <div className="col-lg-3 col-3 pt-1">
                        {
                          this.state.phn.length && this.state.name.length && this.state.city ?
                            <PrimaryButton className="e-p-btn e-login-btn float-right" name="Send Invite" handleClick={this.addBranchAdmin.bind(this)}/>
                          :
                            <PrimaryButton disabled={true} className="e-p-btn e-login-btn float-right e-inActive" name="Send Invite"/>
                        }
                        </div>
                    </React.Fragment>

                }
            </div>


            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
      :
      null
      }
      </>
    );
  }
}

export default InviteAdminModal;
