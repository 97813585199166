
/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import TextButton from '../../components/buttons/textButton';
import ToggleSwitch from './toggleSwitch';


/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';



class BrandCard extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

   componentDidMount() {
  }

  render() {

    return (

        <div className="col-lg-6 col-md-6 col-12 p-2">
            <div className="e-invitation-wrap">
              <div className="row">
                <div className="col-lg-3 col-3 col-md-3 col-xl-2">
                <div className="e-category-image">
                  <div style={{backgroundImage:`url(${this.props.brand.logo})`}} className="e-image-thumb"></div>
                </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center col-xl-7 col-5 col-md-5">
                 <div className="">
                    <span className="d-flex align-items-center"><h5 className="mb-2">{this.props.brand.name}</h5> <span className="mb-2"><TextButton  className="e-t-btn" name="Edit" toggle="modal" target="#updateBrandModal" handleClick={()=>this.props.modalActive(this.props.brand)}/></span></span>
                    <h6 className="mb-0">{`Products: ${this.props.brand.no_of_products}`}</h6>
                  </div>
                </div>
               <div className="col-lg-3 d-flex align-items-center justify-content-end">
                  <span className="e-toggle-switch pr-3">
                    <ToggleSwitch active={this.props.brand.active} id={this.props.brand.id} reload={()=>this.props.getList()}/>
                  </span>
               </div>
             </div>
            </div>
          </div>
    );
  }
}

export default BrandCard;
