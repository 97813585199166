import React, { Component } from 'react';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../../components/loaders/buttonLoader';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';


class PendingList extends Component {
  constructor(props){
    super(props);
    this.state = {
      deleteApiLoading: null,
      apiLoading: null,
      error: null
    }
  }

  deleteInvite(id){
    this.setState({deleteApiLoading: true, error: null});
    let url = devconfig.admin + "/locationadmin/delete-invite";
    let data = JSON.stringify({
      "admin_id": id
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.success("deleted");
        this.setState({deleteApiLoading: false});
      }
      else{
        this.setState({ error: response.message, deleteApiLoading: false});
      }
    }.bind(this))
  }

  resendInvite(id){
    this.setState({apiLoading: true, error: null});
    let url = devconfig.admin + "/locationadmin/resend-invite";
    let data = JSON.stringify({
      "admin_id": id
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.success("resend");
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  render() {
    return (
        <div className="col-lg-4 col-8 p-2">
         <div className="p-1 e-admin-pending">
         <div className="e-invitation-wrap p-4">
        {
          this.state.deleteApiLoading?
          <span className="e-request e-loading">
            <ButtonLoader/>
          </span>
          :
          <span className="e-request" onClick={()=>this.deleteInvite(this.props.data.id)}></span>
        }
         <div className="row">
           <div className="col-lg-8">
             <div className="d-flex flex-column">
                <h5 className="m-0">{this.props.data.mobile} </h5>
                <h6 className="m-0 pt-2 pb-3">{this.props.data.name}</h6>
                <p className="m-0">{this.props.data.city_name}</p>
              </div>
            </div>
           <div className="col-lg-4 py-1">
              <div className="py-3">
                {
                  this.state.apiLoading?
                  <span className="float-right">
                    <ButtonLoader/>
                  </span>
                  :
                  <PrimaryButton className="e-p-btn e-login-btn float-right" name="Resend" handleClick={()=>this.resendInvite(this.props.data.id)}/>
                }
              </div>
           </div>
           {
             this.state.error?
             <div className="col-lg-12 py-2">
            <div className="e-error mb-3">{this.state.error}</div>
           </div>
           :
           null
           }
         </div>
        </div>
         </div>
      </div>
    )
  }
};
export default PendingList;