/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/formComponents/inputBox.js';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../../components/loaders/buttonLoader';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/hooks/post';

class ChangePin extends Component {
  constructor(props){
    super(props);
    this.state = {
      pin: "",
      newPin: "",
      confirmPin: "",
      apiLoading: false,
      error: ""
    }
  }

  componentDidMount() {

  }

  async updatePin(){
    this.setState({ apiLoading: true, error: "" });
    const valid1 = await this.checkNumber(this.state.pin);
    const valid2 = await this.checkNumber(this.state.newPin);
    const valid3 = await this.checkNumber(this.state.confirmPin);
    if(valid1, valid2, valid3){
      if(this.state.newPin === this.state.confirmPin){
        let url = devconfig.admin + '/auth/edit-pin';
        let data = JSON.stringify({
          "current_pin" : this.state.pin,
          "new_pin" : this.state.confirmPin
        })
        await APIPost(url, data).then(function (response) {
          if (response.status === "ok") {
            this.setState({ apiLoading: false, error: "", pin: "", newPin: "", confirmPin: ""});
            $("#changePinModal").modal("hide");
            this.props.success();
          }
          else{
            this.setState({error: response.message, apiLoading: false})
          }
        }.bind(this))
      }
      else{
        this.setState({error: "Enter same PIN in new and confirm PIN boxes", apiLoading: false})
      }
    }
    else{
      this.setState({error: "Use only numbers for the PIN", apiLoading: false})
    }
  }

  checkNumber(query){
    if(query.match(/^\d+$/)) return true;
    else return false;
  }

  render() {

    return (
      <div className="modal fade e-ad-detail-wrap e-invite-admin e-changepin-modal e-profile-pin" id="changePinModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8">
                <div className="modal-header">
                  <h6 className="e-modal-header pl-0">Change PIN</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.setState({pin: "", newPin: "", confirmPin: "", error: ""})}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="">
              <dl className="ev-input-content-wrap">
                <InputBox placeholder="Current PIN" type="password"  value={this.state.pin} valuechange={(number) => this.setState({pin: number})}  required/>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox placeholder="New 6 digit PIN" type="password"  value={this.state.newPin} valuechange={(number) => this.setState({newPin: number})}  required/>
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox placeholder="Confirm 6 digit PIN" type="password"  value={this.state.confirmPin} valuechange={(number) => this.setState({confirmPin: number})}  required/>
                </dd>
              </dl>
            </div>
            <div>
            </div>
            <div className="row pt-3">

              {
                this.state.error.length ? <div className="e-error mb-3">{this.state.error}</div> : null }
                  {
                    this.state.apiLoading?
                      <div className="p-2 float-right">
                        <ButtonLoader/>
                      </div>
                    :
                  <React.Fragment>
                    <div className="col-lg-9 col-9 pr-0">
                      <a href="#" className="float-right pt-3 e-modalcancel" data-dismiss="modal" onClick={()=>this.props.modalClose()}>Cancel</a>
                    </div>
                    <div className="col-lg-3 col-3 pl-0">
                      {
                      this.state.pin && this.state.newPin && this.state.confirmPin  && this.state.pin.length===6 && this.state.newPin.length===6 && this.state.confirmPin.length===6?
                      <PrimaryButton className="e-p-btn e-login-btn float-right" name="Change PIN" handleClick={()=>this.updatePin()}/>
                      :
                      <PrimaryButton className="e-p-btn e-login-btn float-right e-inActive" disabled={true} name="Change PIN"/>
                    }
                    </div>
                  </React.Fragment>
                }

            </div>


            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePin;

