import logo from './logo.svg';
import React, { Component } from 'react';
import {BrowserRouter, Route, Redirect} from 'react-router-dom';


import Dashboard from './container/home/dashboard.js';
import Location from './container/location/location.js';
import Admin from './container/admin/admin.js';
import Detail from './container/admin/detail.js';
import Categories from './container/category/categories.js';
import Sidebar from './components/navigations/sidebar.js';
import Login from './components/login/adminLogin.js';
import Brand from './container/brand/brand.js';
import Profile from './container/profile/profile.js';


class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      sidebarCollaspse: false,
      dDownToggleMob: false,
    }
  }
  collapse(){
    this.setState({sidebarCollaspse: !this.state.sidebarCollaspse});
  }
   dDownToggleMob(){
    this.setState({dDownToggleMob: !this.state.dDownToggleMob});
  }


  // renderSidebar(){
  // let url = window.location.href;
  // if(url == 'http://localhost:3000/dashboard'){
  //   return <Sidebar/>
  // }



  render() {

    if(localStorage.getItem("goodoor_admin")){
      return(
          <BrowserRouter>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 e-sidebar-wrap p-0">
                <Sidebar sidebarCollapse={()=>{this.setState({dDownToggleMob:!this.state.dDownToggleMob})}} dDownToggleMob={this.state.dDownToggleMob}/>
              </div>
              {
                this.state.dDownToggleMob ?
                  <div className="e-logged-dropdown-overlay" onClick={()=>{this.setState({dDownToggleMob:!this.state.dDownToggleMob})}}></div>
                :
                  null
              }
              <div className="col-lg-2 p-0"></div>
              <div className="col-lg-10 p-0 e-content">
                <div className="p-4">
                    <Route path="/" exact render={() => (<Redirect to="/location" />)} />
                    {/* <Route path="/dashboard" component={Dashboard}/> */}
                    <Route path="/location" component={Location}/>
                    <Route path="/branch-admins" component={Admin}/>
                    <Route path="/categories" component={Categories}/>
                    <Route path="/detail" component={Detail}/>
                    <Route path="/brand" component={Brand}/>
                    <Route path="/profile" component={Profile}/>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      )
    }
    else{
      return(
        <section>
          <BrowserRouter>
          <Route path="/" component={Login} exact/>
          <Route path="/" render={() => (<Redirect to="/" />)} />
          </BrowserRouter>
        </section>
      )
    }

  }
}


export default App;
// {this.renderSidebar()}
