
/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import BrandModal from '../../components/modals/newBrandModal';
import UpdateBrandModal from '../../components/modals/updateBrandModal';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import Toast from '../../components/toast/toast.js';
import ConfirmationModal from '../../components/modals/confirmationModal';
import BrandCard from './brandCard';
import NoData from '../../components/warning/noData';
import PageLoader from '../../components/loaders/pageLoader';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';

class Brand extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOn: false,
      addModalOn: false,
      toast: null,
      toastType: null,
      apiLoading: true,
      error: null,
      emptyTable: null,
      brands:[],
      updateBrand: null
    }
  }

   componentDidMount() {
    this.getList();
  }

  getList(){
    this.setState({apiLoading: true, error: null, emptyTable: null});
    let url = devconfig.admin + "/brands/list";
    let data = JSON.stringify({
      "active_status": null,
      "page_num": 1
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.showBrands(response.data);
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
  }

  showBrands(data){
    if(data.length){
      this.setState({brands: data});
    }
    else{
      this.setState({emptyTable: "No brands found!"});
    }
  }
  render() {

    return (
      <React.Fragment>
      <div className="e-main-content e-location e-mt-top">
        <div className="e-border-bottom">
          <div className="row mt-3 ">
            <div className="col-lg-9 col-7">
              <h2 className="pt-2">Brands</h2>
            </div>
            <div className="col-lg-3 col-5">
              <div className="mb-4 float-right">
                <PrimaryButton className="e-p-btn e-login-btn" name="Add New Brand" toggle="modal" target="#newBrandModal" handleClick={()=>this.setState({addModalOn: true})}/>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-4 pl-2">
        { this.state.error ? <div className="col-lg-12"><div className="mt-5 pt-5 text-center"><div className="e-error mb-3">{this.state.error}</div></div></div>: null }
            {
              this.state.apiLoading?
              <div className="col-lg-12">
                <div className="mt-5 pt-5 text-center">
                  <PageLoader/>
                </div>
                </div>
              : this.state.emptyTable?
              <div className="col-lg-12">
                <div className="mt-5 pt-5 text-center">
                  <NoData data={this.state.emptyTable}/>
                </div>
                </div>
              :
                <>
                  {
                    this.state.brands.map(brand=>(
                      <BrandCard brand={brand} modalActive={(brand)=>this.setState({modalOn:true, updateBrand:brand })} getList={()=>{this.getList();
                        this.setState({toast: "Brand status changed", toastType:"success"})
                        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}/>
                    ))
                  }
                </>
            }
        </div>

      </div>
      {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
          :
            null
      }
       <BrandModal success={() => {
          this.setState({toast: "New brand added", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}
          modalClose={()=>{this.setState({addModalOn: false}); this.getList();}} modalOn={this.state.addModalOn}/>
        <UpdateBrandModal success={() => {
          this.setState({toast: "Brand updated", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}
        brandInfo={this.state.updateBrand} updateBrand={(val)=>this.setState({updateBrand: val})}
        modalClose={()=>{this.setState({modalOn: false}); this.getList();}} modalOn={this.state.modalOn}/>
        <ConfirmationModal modalHeading="Do you really want to delete the Brand?" modalMessege="By deleting this brand, the managers won’t be able to add products in this brand to the application."/>
       </React.Fragment>

    );
  }
}

export default Brand;
