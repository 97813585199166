/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
// import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../buttons/primaryButton';
import TextButton from '../buttons/textButton';
import ImageUploader from '../fileUpload/upload';
import InputBox from '../formComponents/inputBox';
import ButtonLoader from '../loaders/buttonLoader'


/*Assets*/


/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';


class brandModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      brandImageLoading: null,
      apiLoading: false,
      error: "",
    }
  }

  modalClose(){
    this.props.modalClose();
    this.setState({
      brandImageLoading: null,
      apiLoading: false,
      error: "",
    })
  }

  triggerClick(tempItem){
    $(tempItem).trigger('click');
  }

  async addBrand(){
    this.setState({apiLoading: true, error: ""});
    const url = devconfig.admin + "/brands/update";
    const data = JSON.stringify({
      "brand_name": this.props.brandInfo.name,
      "brand_image": this.props.brandInfo.logo,
      "brand_id": this.props.brandInfo.id
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        $('#updateBrandModal').modal('hide');
        this.props.success();
        this.modalClose();
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  setImage(value){
    let temp = this.props.brandInfo;
    temp.logo = value;
    this.props.updateBrand(temp);
  }

  setName(e){
    let temp = this.props.brandInfo;
    temp.name = e.target.value;
    this.props.updateBrand(temp);
  }

  componentDidMount() {

  }


  render() {

    return (
      <div className="modal fade e-ad-detail-wrap e-confirm-modal e-category-modal" id="updateBrandModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        {
          this.props.modalOn?
          <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8 pl-0">
                <div className="modal-header">
                  <h6 className="e-modal-header">Edit Brand</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalClose.bind(this)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 pr-0">
                <div className="">

                  {
                    this.props.brandInfo.logo ?
                      <div className="e-invitation-wrap e-upload-image" >
                        <span className="e-request" onClick={() => this.setImage(null)}></span>
                        <div style={{backgroundImage:`url(${this.props.brandInfo.logo})`}} className="e-image-thumb"></div>
                      </div>
                      :
                      <>
                        {
                          this.state.brandImageLoading ?
                            <span className="e-upload-btn e-upload-loader"></span>
                          :
                            <span className="e-upload-btn d-flex align-items-end justify-content-center pb-1" style={{fontSize:"8px", color: " #F16522ee"}} onClick={this.triggerClick.bind(this, "#brand-image-upload")}>500 x 500</span>
                        }
                      </>
                  }

                  <ImageUploader id={"brand-image-upload"} accept={".jpg,.png,.jpeg,.gif"}
                  uploadSuccess={(tempVal) => {this.setState({ brandImageLoading: false }); this.setImage(tempVal)}}
                  uploadStart={() => this.setState({ brandImageLoading: true })}/>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="pt-3">
                  <dl className="ev-input-content-wrap e-m-bottom-20">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input id="brandName" value={this.props.brandInfo.name} type="text"  onChange={this.setName.bind(this)} required/>
                        <label htmlFor="brandName" className="label">Brand Name</label>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="modal-footer pr-0">
            { this.state.error.length ? <div className="col-lg-12 px-0"><div className="e-error mb-3">{this.state.error}</div></div> : null }
              {
                this.state.apiLoading?
                  <div className="col-lg-12 px-0">
                  <div className="float-right">
                        <ButtonLoader/>
                      </div>
                  </div>
                :
                  <React.Fragment>
                    <div className="col-lg-12 px-0">
                      {
                        this.props.brandInfo.name.length && this.props.brandInfo.logo?
                          <PrimaryButton className="e-p-btn e-login-btn float-right"  name="Save Now" handleClick={this.addBrand.bind(this)} />
                        :
                          <PrimaryButton disabled={true} className="e-p-btn e-login-btn float-right e-inActive"  name="Save Now"/>
                      }
                    </div>
                  </React.Fragment>

              }
            </div>
          </div>
        </div>
        :
        <></>
        }
      </div>
    );
  }
}

export default brandModal;
