import React, { Component }  from 'react';

class TextButton extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
}
  render() {
    return(
      <button type="button" className="e-t-btn" disabled={this.props.disabled} onClick={this.props.handleClick} data-target={this.props.target} data-toggle={this.props.toggle}>{this.props.name}</button>
    );
  }
}

export default TextButton;

