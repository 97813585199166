
/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import ChangePinModal from '../../components/modals/changePin';
// import EditProfileModal from '../../components/modals/editProfileModal';
// import EditBankModal from '../../components/modals/editBankModal';
import Toast from '../../components/toast/toast';


/*Assets*/

import Img2 from '../../assets/images/profile.svg';
import Img3 from '../../assets/images/bank.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/hooks/post';
import APIGet from '../../services/hooks/get';


class Profile extends Component {
  constructor(props){
    super(props);
    this.state = {
  
    }
  }

   componentDidMount = async() =>{
    var matches ='';
    
    await this.setState({user: JSON.parse(localStorage.getItem('goodoor_admin_data'))})
    var name = this.state.user.name;
       matches = name.match(/\b(\w)/g);
      if(matches.length > 1 && matches.length == 2){
        var res = matches[0]+matches[1];
      }
      else if(matches.length > 1 && matches.length == 3){
        var res = matches[0]+matches[2];
      }
      else{
        var res = matches[0]
      }
    await this.setState({placeholder: res})

  }


  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }



  render() {

    return (
      <React.Fragment>
        <div className="e-main-content e-location e-branch-detail e-order e-admin-profile">
          
            <>
              <div className="row">
                <div className="col-lg-12 d-flex align-items-center justify-content-between">
                  <h2 className="pt-2">Profile</h2>
                </div>

              </div>
              <div className="row pb-3">
                <div className="col-lg-12">
                  <div className="e-border-bottom"></div>
                </div>
              </div>
              {
                this.state.user && this.state.placeholder ?
                <>
                  <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="e-invitation-wrap pt-2">
                      <div className="row pl-0 p-4">
                        <div className="col-lg-1 col-md-2 col-4">
                          <span className="e-name d-flex align-items-center justify-content-center">{this.state.placeholder}</span>
                        </div>
                        <div className="col-lg-7 col-md-7 col-7">
                        <div className="">
                            <h5 className="">{this.state.user.name}</h5>
                          </div>
                          <div className="row pt-3">
                            <div className="col-lg-3">
                              <h6>Phone</h6>
                              <p>{this.state.user.mobile}</p>
                            </div>
                            <div className="col-lg-3">
                              <h6>Email</h6>
                              <p>{this.state.user.email}</p>
                            </div>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="float-right ml-3 h-100 d-flex flex-column justify-content-between">
                              <span className="mx-n3"><TextButton className="e-t-btn" toggle="modal" target="#changePinModal" name="Change Login PIN"/></span>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                null
               }
            </>
          
        </div>

        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
          :
            null
        }

        <ChangePinModal success={()=>{
          this.setState({toast: "Login PIN updated successfully", toastType:"success", modalOn: false})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
        }}/>
      </React.Fragment>
    );
  }
}

export default Profile;
