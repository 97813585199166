
/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import CategoryModal from '../../components/modals/categoryModal';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import ConfirmationModal from '../../components/modals/confirmationModal';
import ToggleSwitch from './toggleSwitch';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';



class CategoryCard extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

   componentDidMount() {
  }

  render() {

    return (

        <div className="col-lg-6 col-md-6 col-12 p-2">
            <div className="e-invitation-wrap">
              <div className="row">
                <div className="col-lg-3 col-xl-2 col-3 col-md-3">
                <div className="e-category-image">
                  <div style={{backgroundImage:`url(${this.props.category.image})`}} className="e-image-thumb"></div>
                  <span className="e-toggle-switch">
                    <ToggleSwitch active={this.props.category.active} id={this.props.category.id} type={1} reload={()=>this.props.getList()}/>
                  </span>
                </div>
                </div>
                <div className="col-lg-4 d-flex align-items-center col-xl-5 col-5 col-md-5">
                 <div className="">
                    <h5 className="mb-2">{this.props.category.name}</h5>
                    <h6 className="mb-0">{`Sub Categories: ${this.props.category.no_sub_categories}`}</h6>
                  </div>
                </div>
               <div className="col-lg-5 d-flex align-items-center justify-content-end">
                  <div className="pr-3">
                    <TextButton  className="e-t-btn" name="Modify / View" toggle="modal" target="#updateCatModal" handleClick={()=>this.props.modalActive(this.props.category)}/>
                  </div>
               </div>
             </div>
            </div>
          </div>
    );
  }
}

export default CategoryCard;
