
/*Package importing*/
import React, { Component } from 'react';

/*Components*/



/*Assets*/
// import profile from '../../assets/images/profile.png';
// import profPlaceholder from '../../assets/images/profile-placeholder.jpg';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import Img2 from '../../assets/images/profile.svg';
import Img3 from '../../assets/images/bank.svg';



/*Services*/
// import devconfig from '../../config/config.js';
// import APIGet from '../../services/apiCall/get';
// import APIPost from '../../services/apiCall/post';

class Detail extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      apiLoading: false,
      filter: null,
      commissionData: null,
      commissionFilter: 1,
      filterOneDefault: { value: 1, label: 'Last 1 week' },

    }
  }

   componentDidMount() {

  }



  render() {

    return (
      <div className="e-main-content e-location e-branch-detail">
        <h6>Branch List <span>/ Branch Detail</span></h6>
        <div className="row pt-4">
          <div className="col-lg-12">
            <div className="e-border-bottom">
            </div>
          </div>
        </div>
        <h2 className="pt-4">Branch Details</h2>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="e-invitation-wrap pt-2">
              <div className="row pl-0 p-4">
                <div className="col-lg-1 col-md-2 col-4">
                  <img src={Img2} className="pl-2"/>
                </div>
                <div className="col-lg-7 col-md-7 col-7">
                 <div className="">
                    <h5 className="">Franchise One</h5>
                  </div>
                  <div className="row pt-3">
                    <div className="col-lg-4">
                      <h6>Phone</h6>
                      <p>+91 6578345678</p>
                    </div>
                     <div className="col-lg-4">
                      <h6>Operating City</h6>
                      <p>Thodupuzha</p>
                    </div>
                     <div className="col-lg-4">
                      <h6>GSTIN</h6>
                      <p>37AADCB2230M2ZR</p>
                    </div>
                  </div>
                </div>
               <div className="col-lg-4">
                  <div className="float-right ml-4">
                     <PrimaryButton className="e-p-btn e-login-btn e-edit" name="Edit details"/>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4">
              <div className="e-invitation-wrap e-bank-details pt-2">
                <div className="row  p-3">
                  <div className="col-lg-2 col-md-2 col-4">
                    <img src={Img3} className=""/>
                  </div>
                  <div className="col-lg-8 col-8">
                   <div className="pt-2">
                      <h5 className="">Federal Bank</h5>
                      <h6 className="pt-1">XXXXXXXXXXX6784</h6>
                    </div>
                  </div>
                 <div className="col-lg-2">
                    <div className="pt-5 mt-4 float-right">
                      <TextButton  className="e-t-btn" name="Edit"/>
                    </div>
                 </div>
               </div>
              </div>
            </div>
        </div>
      </div>

    );
  }
}

export default Detail;
