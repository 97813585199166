
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import LocationModal from '../../components/modals/locationModal';
import MyTable from './table';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import PageLoader from '../../components/loaders/pageLoader';
import ButtonLoader from '../../components/loaders/buttonLoader';
import NoData from '../../components/warning/noData';
import UpdateLocationModal from '../../components/modals/updateLocationModal';
import Toast from '../../components/toast/toast.js';
import ConfirmationModal from '../../components/modals/confirmationModal';
import ToggleSwitch from './toggleSwitch';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';


class Location extends Component {
  constructor(props){
    super(props);
    this.state = {
      data:[],
      locationStatus: null,
      apiLoading: false,
      error: null,
      togggleApiLoading: false,
      emptyTable: null,
      updateId: 0,
      updateCity: null,
      updateCommission: 0,
      updateLocale: [],
      modalOn: false,
      confirmDisable: false,
      toast: null,
      toastType: null
    }
  }

  componentDidMount() {
    this.getCityList(null);
  }

  getCityList(status){
    this.setState({apiLoading: true, error: null, emptyTable: null});
    let url = devconfig.admin + "/cities/list";
    let data = JSON.stringify({
      "active_status": status
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.locationsData(response.data);
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
  }

  toggleStatus(locationId, active){
    if(active){
      $('#confirmModal').modal('show');
      this.setState({confirmDisable: locationId});
    }
    else{
      this.setState({togggleApiLoading: true, error: null});
    let url = devconfig.admin + "/cities/enable-disable";
    let data = JSON.stringify({
      "city_id": locationId
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({togggleApiLoading: false});
        this.getCityList(this.state.locationStatus);
        this.setState({toast: "Location status changed", toastType:"success"})
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
    }
  }

  updateLocationId(element){
    this.setState({updateId: element.id, updateCity: element.name, updateLocale: element.locations, updateCommission: element.commission_percentage, modalOn: true});
  }

  locationsData(locations){
    let data = [];
    if(locations.length){
        locations.forEach(element=>{
          data[locations.indexOf(element)]=
          {id: element.id,
            active: element.active,
          city_name: element.name,
            delivery_location:  <div className="container px-0">
              {element.locations.map((location)=>(
                <span className={element.active? "badge px-3 m-1 py-2 e-location-badge badge-pill float-left d-flex align-items-center": "badge px-3 m-1 py-2 e-text-disabled e-location-badge badge-pill float-left"} key={`${element.id}location${location.id}${location.name}`}>
                  {location.name}
                  {
                    element.active?
                    <span className="e-toggle-switch pl-2 pt-1">
                    <ToggleSwitch active={location.active} id={location.id} reload={()=>{this.setState({toast: "Location Status Changed", toastType:"success"})
                      setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
                      this.getCityList(this.state.locationStatus);}}/>
                    </span>
                    :
                    null
                  }
                  </span>
              ))}
          </div>
            , last_edited: element.last_modified,
            'action':
            <div className="container d-flex align-items-center justify-content-center py-1">
              <PrimaryButton className="e-p-btn e-login-btn mx-2" name="Edit" key={`${element.id}editButton`} toggle="modal" target="#updateLocationModal" handleClick={this.updateLocationId.bind(this, element)}/>
              {
                this.state.togggleApiLoading?
                  <div className="mx-auto">
                    <ButtonLoader/>
                  </div>
                :
                  <PrimaryButton className={element.active? "e-p-btn e-disable e-login-btn mx-2": "e-p-btn e-enable e-login-btn mx-2"} name={element.active? "Disable": "Enable"} key={`${element.id}statusButton`} handleClick={this.toggleStatus.bind(this, element.id, element.active)}/>
              }
            </div>
        }
      });
      this.setState({data: data});
    }
    else{
      if(this.state.locationStatus===true){
        this.setState({emptyTable: "No active locations found!"});
      }
      else if(this.state.locationStatus===false){
        this.setState({emptyTable: "No disabled locations found!"});
      }
      else{
        this.setState({emptyTable: "No locations found!"})
      }
    }
  }


  render() {
    const options = [
      { value: null, label: 'All' },
      { value: true , label: 'Active' },
      { value: false, label: 'Disabled' },
    ];

    return (
      <React.Fragment>
      <div className="e-main-content e-location e-mt-top">
        <div className="e-border-bottom">
          <div className="row mt-3">
            <div className="col-lg-9 col-md-9 col-6">
              <h2 className="pt-2">Locations</h2>
            </div>
            <div className="col-lg-3 col-md-3 col-6">
              <div className="mb-4 float-right">
                <PrimaryButton className="e-p-btn e-login-btn" toggle="modal" target="#locationModal" name="Add New location" handleClick={()=>this.setState({modalOn: true})} />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-3 pl-2">
          <div className="col-lg-2 col-md-3 col-5">
            <div className="e-filter-wrap pt-2">
              <label>Status</label>
              <SelectBox  placeholder="All" options={options}   selectchange={(tempVal) => {this.setState({locationStatus: tempVal.value}); this.getCityList(tempVal.value);}}/>
            </div>
          </div>
        </div>


        <div className="row mt-5 pl-2">
          <div className="col-lg-12">
            { this.state.error ? <div className="mt-5 pt-5 text-center"><div className="e-error mb-3">{this.state.error}</div></div>: null }
            {
              this.state.apiLoading?
                <div className="mt-5 pt-5 text-center">
                  <PageLoader/>
                </div>
              : this.state.emptyTable?
                <div className="mt-5 pt-5 text-center">
                  <NoData data={this.state.emptyTable}/>
                </div>
              :
                <MyTable data={this.state.data}/>
            }

          </div>
        </div>


      </div>
      {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
          :
            null
      }
      <ConfirmationModal modalHeading="Do you really want to disable location?" modalMessege="By disabling the main location you won’t be able to deliver the groceries to this location and this location will be hidden from the mobile app." confirmDisable={()=> this.toggleStatus(this.state.confirmDisable, false)} />
      <UpdateLocationModal success={() => {
          this.setState({toast: "Location updated successfully", toastType:"success", modalOn: false})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
          this.getCityList(this.state.locationStatus);}} id={this.state.updateId}
          updateCity={this.state.updateCity} updateCityName={(cityName)=> this.setState({updateCity: cityName})}
          updateCommission={this.state.updateCommission} updateCommissionValue={(CommissionValue)=> this.setState({updateCommission: CommissionValue})}
          modalClose={()=>this.setState({modalOn: false})} modalOn={this.state.modalOn}
          updateLocale={this.state.updateLocale} locUpdate={(val) =>this.setState({updateLocale: val})}/>
       <LocationModal success={() => {
          this.setState({toast: "Location added successfully", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
          this.getCityList(this.state.locationStatus)}}
          modalClose={()=>this.setState({modalOn: false})} modalOn={this.state.modalOn}/>
       </React.Fragment>

    );
  }
}

export default Location;
