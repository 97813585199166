
/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import CategoryModal from '../../components/modals/categoryModal';
import UpdateCategoryModal from '../../components/modals/updateCategoryModal';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import Toast from '../../components/toast/toast.js';
import ConfirmationModal from '../../components/modals/confirmationModal';
import CategoryCard from './categoryCard';
import NoData from '../../components/warning/noData';
import PageLoader from '../../components/loaders/pageLoader';

/*Services*/
import devconfig from '../../config/config';
import APIGet from '../../services/hooks/get';

class Categories extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOn: false,
      addModalOn: false,
      toast: null,
      toastType: null,
      apiLoading: true,
      error: null,
      emptyTable: null,
      categories:[],
      updateCategory: null,
      options: []
    }
  }

   componentDidMount() {
    this.getList();
  }

  getList(){
    this.setState({apiLoading: true, error: null, emptyTable: null});
    let url = devconfig.admin + "/categories/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.showCategories(response.data);
        this.setState({apiLoading: false});
        console.log(response.data)
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
  }

  showCategories(data){
    if(data.length){
      let priority = [];
      data.map((cat, i)=>{
        if(i!=0){
          priority.push({value: i, label: i});
        }
        else{
          priority.push({value: null, label: i});
        }
      })
      this.setState({categories: data, options: priority});
    }
    else{
      this.setState({emptyTable: "No categories found!"});
    }
  }
  render() {

    return (
      <React.Fragment>
      <div className="e-main-content e-location e-mt-top">
        <div className="e-border-bottom">
          <div className="row mt-3 ">
            <div className="col-md-6 col-5">
              <h2 className="pt-2">Categories</h2>
            </div>
            <div className="col-md-6 col-7">
              <div className="mb-4 float-right">
                <PrimaryButton className="e-p-btn e-login-btn" toggle="modal" target="#categoryModal" name="Add New Category" handleClick={()=>this.setState({addModalOn: true})}/>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-4 pl-2">
        { this.state.error ? <div className="col-lg-12"><div className="mt-5 pt-5 text-center"><div className="e-error mb-3">{this.state.error}</div></div></div>: null }
            {
              this.state.apiLoading?
              <div className="col-lg-12">
                <div className="mt-5 pt-5 text-center">
                  <PageLoader/>
                </div>
                </div>
              : this.state.emptyTable?
              <div className="col-lg-12">
                <div className="mt-5 pt-5 text-center">
                  <NoData data={this.state.emptyTable}/>
                </div>
                </div>
              :
                <>
                  {
                    this.state.categories.map(category=>(
                      <CategoryCard category={category} modalActive={(category)=>this.setState({modalOn:true, updateCategory:category })} getList={()=>{this.getList();
                        this.setState({toast: "Category status changed", toastType:"success"})
                        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}/>
                    ))
                  }
                </>
            }
        </div>

      </div>
      {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
          :
            null
      }
       <CategoryModal success={() => {
          this.setState({toast: "Category added", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}
          options={this.state.options} modalClose={()=>{this.setState({addModalOn: false}); this.getList();}} modalOn={this.state.addModalOn}/>
        <UpdateCategoryModal success={() => {
          this.setState({toast: "Category updated", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}
        options={this.state.options} categoryInfo={this.state.updateCategory} updateCategory={(val)=>this.setState({updateCategory: val})}
        modalClose={()=>{this.setState({modalOn: false}); this.getList();}} modalOn={this.state.modalOn}/>
        <ConfirmationModal modalHeading="Do you really want to delete the category?" modalMessege="By deleting this category, the managers won’t be able to add products in this category to the application."/>
       </React.Fragment>

    );
  }
}

export default Categories;
