/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/formComponents/inputBox.js';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../loaders/buttonLoader.js';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';


class UpdateLocationModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      locApiLoading: false,
      error: "",
      location: "",
      locations:[]
    }
  }

  componentDidMount() {
  }

  modalClose(){
    this.props.modalClose();
    this.setState({ error: ""});
  }

  addLocation(loc){
    this.setState({location: loc});
    let temp = loc.split(",");
    this.setState({locations: temp});
  }

  async removeLocation(id, index){
    this.setState({locApiLoading: true, error: ""});
    const url = devconfig.admin + "/cities/delete-location";
    const data = JSON.stringify({
      "location_id": id
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        let temp = this.removeBadge(index);
        this.props.locUpdate(temp);
        this.setState({locApiLoading: false});
      }
      else{
        this.setState({ error: response.message, locApiLoading: false});
      }
    }.bind(this))
  }

  removeBadge(index){
    let tempArray = this.props.updateLocale;
    tempArray.splice(index, 1);
    return(tempArray);
  }

  async updateLocation(){
    this.setState({apiLoading: true, error: ""});
    const url = devconfig.admin + "/cities/update";
    const data = JSON.stringify({
      "city_name": this.props.updateCity,
      "commission_percentage": this.props.updateCommission,
      "city_id": this.props.id,
      "locations": this.state.locations
    })
    if(this.props.updateCommission<0){
      this.setState({ error: "Commision Percentage cannot be negative", apiLoading: false});
      
    }
    else{
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          $('#updateLocationModal').modal('hide');
          this.props.success();
          this.setState({apiLoading: false, locations:[]});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this))
    }
  }

  render() {

    return (
      <>
        {
          this.props.modalOn?
          <div className="modal fade e-ad-detail-wrap e-location-modal" id="updateLocationModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8 pl-0">
                <div className="modal-header">
                  <h6 className="e-modal-header">Edit location</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalClose.bind(this)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="pt-3">
              <dl className="ev-input-content-wrap e-m-bottom-20">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Main City" defaultValue={this.props.updateCity} valuechange={(cityName) => this.props.updateCityName(cityName)} required/>
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Commission (%)" type="number" defaultValue={this.props.updateCommission} valuechange={(commisionPercentage) => this.props.updateCommissionValue(commisionPercentage)}  required/>
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Delivery Locations" valuechange={(location) => this.addLocation(location)}  required/>
                </dd>
              </dl>
            </div>
            <div className="container px-0">
              {
                this.state.locApiLoading?
                 <div className="float-left">
                   <ButtonLoader/>
                 </div>
                :
                <>
                {this.props.updateLocale.map((location)=>(
                  <span className="badge px-3 m-1 py-2 e-location-badge badge-pill float-left" key={`location${location.id}${location.name}`}>
                     <button type="button" className="e-request d-flex align-items-center justify-content-center" aria-label="Close" onClick={this.removeLocation.bind(this, location.id, this.props.updateLocale.indexOf(location))}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {location.name}
                    </span>
                ))}
                </>
              }
            </div>
            <div className="row">
            { this.state.error.length ? <div className="col-lg-12 pt-3"><div className="e-error mb-3">{this.state.error}</div></div> : null }
              {
                this.state.apiLoading?
                  <div className="col-lg-12">
                  <div className="float-right">
                        <ButtonLoader/>
                      </div>
                  </div>
                :
                  <React.Fragment>
                    <div className="col-lg-9">
                      <a href="#" className="float-right pt-3 e-modalcancel" data-dismiss="modal" onClick={this.modalClose.bind(this)}>Cancel</a>
                    </div>
                    <div className="col-lg-3">
                      {
                        this.props.updateCity && this.props.updateCommission?
                          <PrimaryButton className="e-p-btn e-login-btn e-modal-confirm float-right"  name="Save Location" handleClick={this.updateLocation.bind(this)} />
                        :
                          <PrimaryButton disabled={true} className="e-p-btn e-login-btn e-modal-confirm float-right e-inActive"  name="Save Location"/>
                      }
                    </div>
                  </React.Fragment>

              }
            </div>

            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
      :
      <></>
        }
      </>
    );
  }
}

export default UpdateLocationModal;
