/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
// import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';



/*Assets*/


/*Services*/




class ConfirmationModal extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }


  componentDidMount() {

  }


  render() {


    return (
      <div className="modal fade e-ad-detail-wrap e-confirm-modal" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-10">
                <div className="modal-header">
                  <h6 className="e-modal-header">{this.props.modalHeading}</h6>
                </div>
              </div>
              <div className="col-lg-2">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="e-border-bottom">
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                  <p className="pl-3">{this.props.modalMessege}</p>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
              <div className="d-flex float-right pt-4">
                <a href="#" className="pt-3 e-modalcancel mx-3" data-dismiss="modal">Cancel</a>          
                <PrimaryButton className="e-p-btn e-login-btn ml-1" name="Yes, Disable" handleClick={() => {this.props.confirmDisable(); $('#confirmModal').modal('show'); $('#confirmModal').modal('hide'); }} /></div>
            </div>
            </div>
            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationModal;
