/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/formComponents/inputBox.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
// import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../loaders/buttonLoader.js';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';


class LocationModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      city: "",
      location: "",
      commission: "",
      apiLoading: false,
      error: ""
    }
  }

  componentDidMount() {

  }

  async addLocation(){
    this.setState({apiLoading: true, error: ""});
    var url = devconfig.admin + "/cities/add";
    var data = JSON.stringify({
      "city_name": this.state.city,
      "commission_percentage": this.state.commission,
      "locations": this.state.location
    })
    if(this.state.commission<0){
      this.setState({ error: "Commision Percent cannot be negative", apiLoading: false});
     
    }
    else{
       APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          $('#locationModal').modal('hide');
          this.props.success();
          this.props.modalClose();
          this.setState({apiLoading: false});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this))
    }
  }

  render() {

    return (
      <div className="modal fade e-ad-detail-wrap e-location-modal" id="locationModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        {
          this.props.modalOn?
          <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8 pl-0">
                <div className="modal-header">
                  <h6 className="e-modal-header">Add new delivery location</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.modalClose()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="pt-2">
              <dl className="ev-input-content-wrap e-m-bottom-20">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Main City" valuechange={(cityName) => this.setState({city: cityName})} required/>
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Delivery Locations" valuechange={(locations) => this.setState({location: locations})}  required/>
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox label="Commission in (percentage)" type="number" valuechange={(commisionPercentage) => this.setState({commission: commisionPercentage})}  required/>
                  <label htmlFor="u-pin"  className="label u-email"></label>
                </dd>
              </dl>
            </div>
            <div className="row pt-3">
            { this.state.error.length ? <div className="col-lg-12"><div className="e-error mb-3">{this.state.error}</div></div> : null }
              {
                this.state.apiLoading?
                  <div className="col-lg-12">
                  <div className="float-right">
                        <ButtonLoader/>
                      </div>
                  </div>
                :
                  <React.Fragment>
                    <div className="col-lg-9 col-9">
                      <a href="#" className="float-right pt-3 e-modalcancel" data-dismiss="modal" onClick={()=>this.props.modalClose()}>Cancel</a>
                    </div>
                    <div className="col-lg-3 col-3">
                      {
                        this.state.city.length && this.state.location.length && this.state.commission.length?
                          <PrimaryButton className="e-p-btn e-login-btn e-modal-confirm float-right"  name="Save Location" handleClick={this.addLocation.bind(this)} />
                        :
                          <PrimaryButton disabled={true} className="e-p-btn e-login-btn e-modal-confirm float-right e-inActive"  name="Save Location"/>
                      }
                    </div>
                  </React.Fragment>

              }
            </div>

            <div className="modal-footer">

            </div>
          </div>
        </div>
        :
        null
        }
      </div>
    );
  }
}

export default LocationModal;
