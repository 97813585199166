
/*Package importing*/
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';

/*Components*/
import InviteAdminModal from '../../components/modals/inviteAdminModal';
import AdminTable from '../../components/dataTable/adminTable';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import 'react-dates/initialize';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import DateRange from '../../components/dateRangePicker/dateRange';
import ConfirmationModal from '../../components/modals/confirmationModal';
import Toast from '../../components/toast/toast.js';
import PageLoader from '../../components/loaders/pageLoader';
import ButtonLoader from '../../components/loaders/buttonLoader';
import NoData from '../../components/warning/noData';
import PendingList from './pendingBranchAdmin';
import EditAdminModal from '../../components/modals/editAdminModal';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';
import APIGet from '../../services/hooks/get';



class Admin extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 1,
      data:[],
      locationAdminStatus: null,
      apiLoading: false,
      error: null,
      togggleApiLoading: false,
      modalOn: false,
      confirmDisable: false,
      toast: null,
      toastType: null,
      pendingList: null,
      modalOn: false,
      editModalOn: false,
      updateData:{},
      city_List: [{value: 0, label: "All"}],
      city_List_props:[],
      city: null
    }
  }

   componentDidMount() {
    this.getAdminList();
    this.getCityList();
  }

  pendingAction(action){
    this.getPendingList()
    this.setState({toast: `Invitation ${action} successfully`, toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }

  getCityList(){
    var url = devconfig.admin + "/cities/general-list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        const temp = response.data;
        let cityOptions = this.state.city_List;
        temp.forEach(city=>{
          cityOptions.push({value: city.id, label: city.name});
        })
        let temp1 = cityOptions;
        temp1.shift();
        this.setState({city_List: cityOptions, city_List_props: temp1});
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
  }

  getAdminList(){
    this.setState({apiLoading: true, error: null, emptyTable: null});
    let url = devconfig.admin + "/locationadmin/list";
    let data = JSON.stringify({
      "active_status": this.state.locationAdminStatus,
      "city_id": this.state.city,
      "page_num": 1
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.locationAdminData(response.data);
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
  }

  toggleStatus(adminId, active){
    if(active){
      $('#confirmModal').modal('show');
      this.setState({confirmDisable: adminId});
    }
    else{
      this.setState({togggleApiLoading: true, error: null});
    let url = devconfig.admin + "/locationadmin/block-unblock";
    let data = JSON.stringify({
      "admin_id": adminId
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({togggleApiLoading: false});
        this.getAdminList();
        this.setState({toast: "Branch-admin status changed", toastType:"success"})
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
    }
  }

  updateAdmin(admin){
    let temp={}
    if(!admin.contact_person && !admin.gst){
      temp = {...admin, contact_person: "", gst: ""};
    }
    this.setState({editModalOn: true, updateData: temp});
  }

  locationAdminData(locations){
    let data = [];
    if(locations.length){
        locations.forEach(element=>{
          data[locations.indexOf(element)]=
          {id: element.id,
            active: element.active,
            city_name: element.city_name,
            name: element.name ,
            mobile: element.mobile ,
            no_of_shops: element.no_of_shops ,
            created: element.created ,
            created_date: moment(element.created, "DD MMM YYYY").format("YYMMDD"),
            'action':
            <div className="container d-flex align-items-center justify-content-center py-1">
              <PrimaryButton className="e-p-btn e-login-btn mx-2" name="Edit" key={`${element.id}editButton`} toggle="modal" target="#editAdminModal" handleClick={this.updateAdmin.bind(this, element)}/>
              {
                this.state.togggleApiLoading?
                  <div className="mx-auto">
                    <ButtonLoader/>
                  </div>
                :
                  <PrimaryButton className={element.active? "e-p-btn e-disable e-login-btn mx-2": "e-p-btn e-enable e-login-btn mx-2"} name={element.active? "Block": "Unblock"} key={`${element.id}statusButton`} handleClick={this.toggleStatus.bind(this, element.id, element.active)}/>
              }
            </div>
        }
      });
      this.setState({data: data});
    }
    else{
        if(this.state.locationAdminStatus===true){
          this.setState({emptyTable: "No Active Branch Admins found!"});
        }
        else if(this.state.locationAdminStatus===false){
          this.setState({emptyTable: "No Disabled Branch Admins found!"});
        }
        else{
          this.setState({emptyTable: "No Branch Admins found!"});
        }
    }
  }

  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
    if(tempItem===1) { this.setState({locationAdminStatus: null, city: null}); this.getAdminList();}
    else if(tempItem===2) this.getPendingList();
  }

  getPendingList(){
    this.setState({apiLoading: true, error: null, emptyTable: null});
    let url = devconfig.admin + "/locationadmin/pending";
    let data = JSON.stringify({
      "page_num": 1
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.pendingList(response.data);
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message});
      }
    }.bind(this))
  }

  pendingList(data){
    if(data.length){
      this.setState({pendingList: data});
    }
    else{
      this.setState({emptyTable: "Pending List Empty!"})
    }
  }

  render() {
    const options = [
      { value: null, label: 'All' },
      { value: true , label: 'Active' },
      { value: false, label: 'Disabled' },
    ];

     const renderButton = ()=>{
      switch(this.state. tabIndex) {
        case 1:{
          return  <PrimaryButton className="e-p-btn e-login-btn" name="Invite Admin" handleClick={async ()=>{await this.setState({modalOn: false}); await this.setState({modalOn: true}); $("#inviteAdminModal").modal("show")}}/>
      }
    }
  }

    return (
      <React.Fragment>
      <div className="e-main-content e-location e-admin e-mt-top">
        <div className="">
          <div className="row mt-2">
            <div className="col-lg-8 col-8 pt-3">
              {
              this.state.tabIndex === 1 ?
                <span className="e-tabs-item e-active pr-4">Branch Admins</span>
              :
                <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive pr-4">Branch Admins</span>
            }

            {
              this.state.tabIndex === 2 ?
                <span className="e-tabs-item e-active pr-4">Pending Invitations</span>
              :
                <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive">Pending Invitations</span>
            }
            </div>
            <div className="col-md-4 col-4 mt-2">
              <div className="text-right mb-3">
                {renderButton()}
              </div>
            </div>

          </div>
        </div>

         {
          this.state.tabIndex === 1 ?
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="e-border-bottom">

                  </div>
                </div>
              </div>
              <div className="row pt-5 pl-2">
                <div className="col-lg-2 col-md-3 col-5">
                  <div className="e-filter-wrap pt-2">
                    <label>Status</label>
                    <SelectBox  placeholder="All" options={options}   selectchange={async (tempVal) => {await this.setState({locationAdminStatus: tempVal.value}); this.getAdminList();}}/>
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-5">
                  <div className="e-filter-wrap pt-2">
                    <label>Location</label>
                    <SelectBox  placeholder="All" options={this.state.city_List}   selectchange={async (tempVal) => {await this.setState({city: tempVal.value}); this.getAdminList();}}/>
                  </div>
                </div>
              </div>
              <div className="row mt-5 pl-2">
                <div className="col-lg-12">
                { this.state.error ? <div className="mt-5 pt-5 text-center"><div className="e-error mb-3">{this.state.error}</div></div>: null }
            {
              this.state.apiLoading?
                <div className="mt-5 pt-5 text-center">
                  <PageLoader/>
                </div>
              : this.state.emptyTable?
                <div className="mt-5 pt-5 text-center">
                  <NoData data={this.state.emptyTable}/>
                </div>
              :
              <AdminTable data={this.state.data}/>
            }
                </div>
              </div>
            </div>
          :
            null
        }
        {
          this.state.tabIndex === 2 ?
            <div>
              <div className="row pt-4">
                <div className="col-lg-12">
                  <div className="e-border-bottom">

                  </div>
                </div>
              </div>
              <div className="row pt-5 pl-2">
                { this.state.error ? <div className="mt-5 pt-5 text-center"><div className="e-error mb-3">{this.state.error}</div></div>: null }
                {
                  this.state.apiLoading?
                    <div className="col-12 mt-5 pt-5">
                    <div className="mt-5 pt-5 text-center">
                      <PageLoader/>
                    </div>
                    </div>
                  : this.state.emptyTable?
                  <div className="col-12 mt-5 pt-5">
                    <div className="mt-5 pt-5 text-center">
                      <NoData data={this.state.emptyTable}/>
                    </div>
                    </div>
                  :
                  <>
                    {
                      this.state.pendingList.map((element =>(
                        <PendingList data={element} key={`pending_${element.name}_${element.mobile}`} success={(data)=>this.pendingAction(data)}/>
                      )))
                    }
                  </>
                }

              </div>
              <div className="row mt-5 pl-2">
                <div className="col-lg-12">

                </div>
              </div>
            </div>
          :
            null
        }
      </div>

      {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
          :
            null
      }
       <ConfirmationModal modalHeading="Do you really want to block  branch admin?" modalMessege="By disabling this branch admin, they won’t be able to add vendors and products to the application." confirmDisable={()=> this.toggleStatus(this.state.confirmDisable, false)}/>
       <EditAdminModal city_List={this.state.city_List_props} success={() => {this.getAdminList();
          this.setState({toast: "Admin details updated", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}
          modalOn={this.state.editModalOn} modalClose={()=>this.setState({editModalOn:false})}
          data={this.state.updateData} editData={(temp)=>this.setState({updateData: temp})}/>
       {
         this.state.modalOn?
         <InviteAdminModal city_List={this.state.city_List_props} success={() => {
          this.setState({toast: "Invitation has been sent successfully", toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);}}
          modalOn={this.state.modalOn} modalClose={()=>this.setState({modalOn:false})}/>
          :
          null
       }
       </React.Fragment>
    );
  }
}


export default Admin;
