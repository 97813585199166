
/*Package importing*/
import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController,isInclusivelyAfterDay} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Components*/


/*Assets*/


class DateRange extends Component {
  constructor(props){
    super(props);
    this.state = {
      initalDate: moment().format(),
      startDate: null,
      endDate: null,
    }
  }

  componentDidMount() {

  }


  onDatesChange = async ({ startDate, endDate }) => {
    this.setState({ startDate: startDate, endDate:  endDate})

    if(startDate && endDate){
      var tempStart = startDate.format("YYYY/MM/DD");
      var tempEnd = endDate.format("YYYY/MM/DD");

      this.props.dateChange(tempStart, tempEnd)
    }
    else{
      this.props.dateChange('2020/11/01', moment().add(2, 'days').utcOffset('+05:30').format('YYYY/MM/DD'))
    }
  }

  render() {
    return (
      <div className="">
        <DateRangePicker
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={this.onDatesChange.bind(this)}// PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(2, 'days'))}
          small={true}
          displayFormat="DD MMM 'YY"
          showDefaultInputIcon
          inputIconPosition="after"
          showClearDates
        />
      </div>

    );
  }
}

export default DateRange;
