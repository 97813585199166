/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import ImageUploader from '../../components/fileUpload/upload';
import InputBox from '../formComponents/inputBox';
import ButtonLoader from '../../components/loaders/buttonLoader'


/*Assets*/


/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';
import ToggleSwitch from '../../container/category/toggleSwitch';


class UpdateCategoryModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      imageLoader: null,
      subImageLoader: new Array(12),
      apiLoading: false,
      error: "",
    }
  }

  modalClose(){
    this.props.modalClose();
    this.setState({
      imageLoader : null,
      subImageLoader: [null, null],
      apiLoading: false,
      error: "",
    })
  }

  triggerClick(tempItem){
    $(tempItem).trigger('click');
  }

  categoryImage(value){
    let temp = this.props.categoryInfo;
    temp.image = value;
    this.props.updateCategory(temp);
  }

  changeName(name){
    let temp = this.props.categoryInfo;
    temp.name = name;
    this.props.updateCategory(temp);
  }

  removeImage(index){
    let temp = this.props.categoryInfo;
    temp.sub_categories[index].image = null;
    this.props.updateCategory(temp);
  }

  addName(index, e){
    let temp = this.props.categoryInfo;
    temp.sub_categories[index].name = e.target.value;
    this.props.updateCategory(temp);
  }

  imageUploaded(url, index){
    let temp1 = this.state.subImageLoader;
    let temp2 = this.props.categoryInfo;
    temp1[index] = false;
    temp2.sub_categories[index].image = url;
    this.setState({subImageLoader: temp1});
    this.props.updateCategory(temp2);
  }

  imageUploading(index){
    let temp1 = this.state.subImageLoader;
    temp1[index] = true;
    this.setState({subImageLoader: temp1})
  }

  deleteSubCat(index){
    let temp1 = this.state.subImageLoader;
    let temp2 = this.props.categoryInfo;
    const length = temp1.length;
    temp1.splice(index, 1);
    temp2.sub_categories.splice(index, 1);
    this.setState({subImageLoader: temp1});
    this.props.updateCategory(temp2);
  }

  addSubCat(){
    let temp1 = this.state.subImageLoader;
    let temp2 = this.props.categoryInfo;
    temp1.push(null);
    temp2.sub_categories.push({name: "", image_url: null});
    this.setState({subImageLoader: temp1});
    this.props.updateCategory(temp2);
  }
  changeStatus(index){
    let temp2 = this.props.categoryInfo;
    temp2.sub_categories[index].active = !temp2.sub_categories[index].active;
    this.props.updateCategory(temp2);
  }

  async editCategory(){
    this.setState({apiLoading: true, error: ""});
    let info = this.props.categoryInfo;
    info.sub_categories.forEach((element,i) => {
      if(!element.name.length){
        info.sub_categories.splice(i, 1);
      }
    });
    await this.props.updateCategory(info);
    const data = await JSON.stringify(info);
    const url = devconfig.admin + "/categories/update";
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        $('#updateCatModal').modal('hide');
        console.log(data)
        this.props.success();
        this.modalClose();
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  componentDidMount() {

  }


  render() {

    return (
      <div className="modal fade e-ad-detail-wrap e-confirm-modal e-category-modal" id="updateCatModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        {
          this.props.modalOn?
          <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8 pl-0">
                <div className="modal-header">
                  <h6 className="e-modal-header">Manage Category & Sub Category</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalClose.bind(this)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 pr-0">
                <div className="">

                  {
                    this.props.categoryInfo.image ?
                      <div className="e-invitation-wrap e-upload-image" >
                        <span className="e-request" onClick={this.categoryImage.bind(this, null)}></span>
                        <div style={{backgroundImage:`url(${this.props.categoryInfo.image})`}} className="e-image-thumb"></div>
                      </div>
                      :
                      <>
                        {
                          this.state.imageLoader ?
                            <span className="e-upload-btn e-upload-loader"></span>
                          :
                            <span className="e-upload-btn d-flex align-items-end justify-content-center pb-1" style={{fontSize:"8px", color: " #F16522ee"}} onClick={this.triggerClick.bind(this, "#category-image-update-upload")}>500 x 500</span>
                        }
                      </>
                  }

                  <ImageUploader id={"category-image-update-upload"} accept={".jpg,.png,.jpeg,.gif"}
                  uploadSuccess={(tempVal) => {this.setState({imageLoader: false }); this.categoryImage(tempVal);}}
                  uploadStart={() => this.setState({ imageLoader: true })}/>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="pt-3">
                  <InputBox id="categoryName" type="text" label="Category Name" defaultValue={this.props.categoryInfo.name} valuechange={(name) => this.changeName(name)}/>
                </div>
              </div>
              <div className="col-lg-2 e-priority-label  position-relative">
                <div className="pt-4 mt-1">
                  <SelectBox placeholder="0" defaultValue={this.props.categoryInfo.order===null? {value: null, label: 0}: {value:this.props.categoryInfo.order, label: this.props.categoryInfo.order}} options={this.props.options}   selectchange={(tempVal) => {let temp = this.props.categoryInfo; temp.order= tempVal.value; this.props.updateCategory(temp);}}/>
                  <label>Priority</label>
                </div>
              </div>
            </div>
            <div className="row pt-2">
                <div className="col-lg-12">
                  <div className="e-border-bottom"></div>
                </div>
            </div>

            <>
            {
              this.props.categoryInfo.sub_categories.map(subCat =>(
                <div className="row pt-3">
              <div className="col-lg-2 pr-0">
                <div className="">
                {
                    subCat.image ?
                      <div className="e-invitation-wrap e-upload-image" >
                        <span className="e-request" onClick={this.removeImage.bind(this, this.props.categoryInfo.sub_categories.indexOf(subCat))}></span>
                        <div style={{backgroundImage:`url(${subCat.image})`}} className="e-image-thumb"></div>
                      </div>
                      :
                      <>
                        {
                          this.state.subImageLoader[this.props.categoryInfo.sub_categories.indexOf(subCat)] ?
                            <span className="e-upload-btn e-upload-loader"></span>
                          :
                            <span className="e-upload-btn d-flex align-items-end justify-content-center pb-1" style={{fontSize:"8px", color: " #F16522ee"}} onClick={this.triggerClick.bind(this, `#Subcategory-${this.props.categoryInfo.sub_categories.indexOf(subCat)}-image-update-upload`)}>500 x 500</span>
                        }
                      </>
                  }

                  <ImageUploader id={`Subcategory-${this.props.categoryInfo.sub_categories.indexOf(subCat)}-image-update-upload`} accept={".jpg,.png,.jpeg,.gif"}
                  uploadSuccess={(tempVal) => this.imageUploaded(tempVal, this.props.categoryInfo.sub_categories.indexOf(subCat), this)}
                  uploadStart={this.imageUploading.bind(this, this.props.categoryInfo.sub_categories.indexOf(subCat))}/>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="pt-3">
                  <dl className="ev-input-content-wrap e-m-bottom-20">
                    <dd className="ev-input-contentbox ev-small-input-contentbox">
                      <input id={`Subcategory-${this.props.categoryInfo.sub_categories.indexOf(subCat)}-input`} value={subCat.name} type="text" placeholder={this.props.placeholder} onChange={this.addName.bind(this, this.props.categoryInfo.sub_categories.indexOf(subCat))} required/>
                      <label htmlFor={`Subcategory-${this.props.categoryInfo.sub_categories.indexOf(subCat)}-input`} className="label">Sub Category Name</label>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="col-lg-2 px-0">
                  {
                    subCat.id?
                    <div className="pt-3 d-flex align-items-center pl-3" style={{height:" 65px"}} >
                    <ToggleSwitch active={subCat.active} id={subCat.id} type={2} reload={this.changeStatus.bind(this, this.props.categoryInfo.sub_categories.indexOf(subCat))}/>
                  </div>
                  :
                  <div className="pt-3 d-flex align-items-center pl-3" style={{height:" 65px"}} >
                    <span className="e-delete" onClick={this.deleteSubCat.bind(this, this.props.categoryInfo.sub_categories.indexOf(subCat))}></span>
                  </div>
                  }
              </div>
              </div>
              ))
            }
            </>

            <div className="row">
              <div className="pl-2">
                <TextButton  className="e-t-btn" name="+ Add Sub Category" handleClick={this.addSubCat.bind(this)}/>
              </div>
            </div>

            <div className="modal-footer pr-0">
            { this.state.error.length ? <div className="col-lg-12"><div className="e-error mb-3">{this.state.error}</div></div> : null }
              {
                this.state.apiLoading?
                  <div className="col-lg-12">
                  <div className="float-right">
                        <ButtonLoader/>
                      </div>
                  </div>
                :
                  <React.Fragment>
                    <div className="col-lg-12">
                      {
                        this.props.categoryInfo.name.length && this.props.categoryInfo.image?
                          <PrimaryButton className="e-p-btn e-login-btn float-right"  name="Save Now" handleClick={this.editCategory.bind(this)} />
                        :
                          <PrimaryButton disabled={true} className="e-p-btn e-login-btn float-right e-inActive"  name="Save Now"/>
                      }
                    </div>
                  </React.Fragment>

              }
            </div>
          </div>
        </div>
        :
        <></>
        }
      </div>
    );
  }
}

export default UpdateCategoryModal;
