 /*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import logo from '../../assets/images/SA-logo.png';


class Header extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentWillMount(){

  }





  render() {
    return (

      <div>
        <header className="e-header e-header-nav pl-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <a className="" href="/">
                  <img className="e-logo pt-3 pl-3" src={logo} alt="Goodoor admin"/>
                </a>
              </div>

            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
