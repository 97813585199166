/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/formComponents/inputBox.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../../components/loaders/buttonLoader.js';



/*Assets*/


/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';
import APIGet from '../../services/hooks/get';
import PhoneValidation from '../../services/validation/phone';



class EditAdminModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phn: "",
            name: "",
            city: 0,
            apiLoading: false,
            error: "",
            value: ""
        }
    }

    async editBranchAdmin() {
        this.setState({ apiLoading: true, error: "" });
        if(!this.state.city)this.setState({city: this.props.data.city_id});
        let mob = this.props.data.mobile.slice(3);
        let tempValid = await PhoneValidation(mob);
        if (tempValid) {
            var url = devconfig.admin + "/locationadmin/update";
            var data = JSON.stringify({
                "name": this.props.data.name,
                "mobile": this.props.data.mobile,
                "city_id": this.state.city? this.state.city: this.props.data.city_id,
                "admin_id": this.props.data.id,
                "gst_no": this.props.data.gst,
                "contact_person": this.props.data.contact_person
            })
            APIPost(url, data).then(function (response) {
                if (response.status === "ok") {
                    $('#editAdminModal').modal('hide');
                    this.props.success();
                    this.props.modalClose();
                    this.setState({ apiLoading: false });
                }
                else {
                    this.setState({ error: response.message, apiLoading: false });
                }
            }.bind(this))
        }
        else {
            this.setState({ error: "Add a valid mobile number.", apiLoading: false });
        }
    }


    componentDidMount() {
    }

    valueChange(type, e) {
        let temp = this.props.data;
        temp[type] = e.target.value;
        this.props.editData(temp);
    }


    render() {

        return (
            <>
                {
                    this.props.modalOn ?
                        <div className="modal fade e-ad-detail-wrap e-invite-admin" id="editAdminModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="row">
                                        <div className="col-lg-8 pl-0">
                                            <div className="modal-header">
                                                <h6 className="e-modal-header">Edit Admin</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.modalClose()}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="pt-3">
                                        <dl className="ev-input-content-wrap e-m-bottom-20">
                                            <dd className="ev-input-contentbox ev-small-input-contentbox">
                                                <input id="Name_of_the_Admin" value={this.props.data.name} type="text" onChange={this.valueChange.bind(this, "name")} required />
                                                <label htmlFor="Name_of_the_Admin" className="label">Name of the Admin / Franchise</label>
                                            </dd>
                                        </dl>
                                        <dl className="ev-input-content-wrap e-m-bottom-20">
                                            <dd className="ev-input-contentbox ev-small-input-contentbox">
                                                <input id="Phone" value={this.props.data.mobile} type="text" onChange={this.valueChange.bind(this, "mobile")} required />
                                                <label htmlFor="Phone" className="label">Phone Number</label>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="pt-3">
                                        <dl className="ev-input-content-wrap e-m-bottom-20">
                                            <dd className="ev-input-contentbox ev-small-input-contentbox">
                                                <input id="contact_person" value={this.props.data.contact_person} type="text" onChange={this.valueChange.bind(this, "contact_person")} required />
                                                <label htmlFor="contact_person" className="label">Contact Person</label>
                                            </dd>
                                        </dl>
                                        <dl className="ev-input-content-wrap e-m-bottom-20">
                                            <dd className="ev-input-contentbox ev-small-input-contentbox">
                                                <input id="gst_no" value={this.props.data.gst} type="text" onChange={this.valueChange.bind(this, "gst")} required />
                                                <label htmlFor="gst_no" className="label">GST number</label>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div>
                                        <div className="e-filter-wrap pt-2">
                                            <SelectBox placeholder="Choose City" defaultValue={{ "value": this.props.data.city_id, "label": this.props.data.city_name }} options={this.props.city_List} selectchange={(cityID) => this.setState({ city: cityID.value })} />
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        {this.state.error.length ? <div className="col-lg-12 pt-1"><div className="e-error mb-3">{this.state.error}</div></div> : null}
                                        {
                                            this.state.apiLoading ?
                                                <div className="col-lg-12 pt-1">
                                                    <div className="float-right">
                                                        <ButtonLoader />
                                                    </div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <div className="col-lg-10 pt-1">
                                                        <a href="#" className="float-right pt-3 e-modalcancel" data-dismiss="modal" onClick={() => this.props.modalClose()}>Cancel</a>
                                                    </div>
                                                    <div className="col-lg-2 pt-1">
                                                        {
                                                            this.props.data ?
                                                                <>
                                                                    {
                                                                        this.props.data.name && this.props.data.mobile && this.props.data.contact_person && this.props.data.gst && this.props.data.city_id ?
                                                                            <PrimaryButton className="e-p-btn e-login-btn float-right" name="Save" handleClick={this.editBranchAdmin.bind(this)} />
                                                                            :
                                                                            <PrimaryButton disabled={true} className="e-p-btn e-login-btn float-right e-inActive" name="Save" />
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </React.Fragment>

                                        }
                                    </div>


                                    <div className="modal-footer">

                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </>
        );
    }
}

export default EditAdminModal;
