/*Package importing*/
import React, { Component } from 'react';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/hooks/post';

class ImageUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
        }
    }

    componentDidMount() {
    }

    async uploadDocument(e) {
        if (e.target.files[0]) {
            this.props.uploadStart();
            var tempFileName = e.target.files[0].name;
            var fileReader = new FileReader();
            var tempKey = 'admin/' + this.props.userID
            if (e.target.files != null) {
                if (this.props.imageSelected) {
                    this.props.imageSelected();
                }
                fileReader.readAsDataURL(e.target.files[0]);
                fileReader.onloadend = function (event) {
                    var file = event.target.result;
                    var url = devconfig.admin + "/utils/upload-image";
                    var data = JSON.stringify({
                        'file': file,
                        'fileName': tempFileName,
                        'key': tempKey
                    })
                    APIPost(url, data).then(function (response) {
                        if (response.status === "ok") {
                            this.setState({ file: response.cdn_url });
                            this.props.uploadSuccess(response.cdn_url, JSON.parse(data));
                            this.setState({ apiLoading: false });
                        }
                        else {
                            this.setState({ apiLoading: false });
                        }
                    }.bind(this))
                }.bind(this)
            }
        }
        else {
            console.log("Insinde upload")
        }
    } render() {
        return (
            <input id={this.props.id} type="file" accept={this.props.accept} onChange={this.uploadDocument.bind(this)} />
        );
    }
} export default ImageUploader;