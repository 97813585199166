import React from 'react';

/*Image*/
import empty from '../../assets/images/illustrations/empty.svg';

function NoData(props){
  return(

  	<div className="col-12 e-no-data-wrap text-center">
  		<img src={empty}/>
  		<h6 className="pb-4">{props.data}</h6>
  	</div>
    
  )
}

export default NoData;