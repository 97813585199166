/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
// import DateRange from '../../components/dateRangePicker/dateRange.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import ImageUploader from '../../components/fileUpload/upload';
import InputBox from '../formComponents/inputBox';
import ButtonLoader from '../../components/loaders/buttonLoader'


/*Assets*/


/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/hooks/post';


class CategoryModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      categoryName: "",
      categoryImage:null,
      categoryImageLoading: null,
      subCategory: [{name: "", image_url: null}, {name: "", image_url: null}],
      subCatImageLoading: [null, null],
      apiLoading: false,
      error: "",
      order: null
    }
  }

  modalClose(){
    this.props.modalClose();
    this.setState({
      categoryName: "",
      categoryImage:null,
      categoryImageLoading: null,
      subCategory: [{name: "", image_url: null}, {name: "", image_url: null}],
      subCatImageLoading: [null, null],
      apiLoading: false,
      error: "",
      order: null
    })
  }

  triggerClick(tempItem){
    $(tempItem).trigger('click');
  }

  removeImage(index){
    let temp = this.state.subCategory;
    temp[index].image_url = null;
    this.setState({subCategory: temp});
  }

  addName(index, e){
    let temp = this.state.subCategory;
    temp[index].name = e.target.value;
    this.setState({subCategory: temp});
  }

  imageUploaded(url, index){
    let temp1 = this.state.subCatImageLoading;
    let temp2 = this.state.subCategory;
    temp1[index] = false;
    temp2[index].image_url = url;
    this.setState({subCatImageLoading: temp1, subCategory: temp2})
  }

  imageUploading(index){
    let temp1 = this.state.subCatImageLoading;
    temp1[index] = true;
    this.setState({subCatImageLoading: temp1})
  }

  deleteSubCat(index){
    let temp1 = this.state.subCatImageLoading;
    let temp2 = this.state.subCategory;
    const length = temp1.length;
    temp1.splice(index, 1);
    temp2.splice(index, 1);
    this.setState({subCatImageLoading: temp1, subCategory: temp2})
  }

  addSubCat(){
    let temp1 = this.state.subCatImageLoading;
    let temp2 = this.state.subCategory;
    temp1.push(null);
    temp2.push({name: "", image_url: null});
    this.setState({subCatImageLoading: temp1, subCategory: temp2});
  }

  async addCategory(){
    this.setState({apiLoading: true, error: ""});
    let info = {
      "category_name": this.state.categoryName,
      "category_image_url": this.state.categoryImage,
      "sub_categories": this.state.subCategory,
      "order": this.state.order
    };
    info.sub_categories.forEach((element,i) => {
      if(!element.name.length){
        info.sub_categories.splice(i, 1);
      }
    });
    const data = await JSON.stringify(info);
    const url = devconfig.admin + "/categories/add";
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        $('#categoryModal').modal('hide');
        this.props.success();
        this.modalClose();
        this.setState({apiLoading: false});
      }
      else{
        this.setState({ error: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  componentDidMount() {

  }


  render() {

    return (
      <div className="modal fade e-ad-detail-wrap e-confirm-modal e-category-modal" id="categoryModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        {
          this.props.modalOn?
          <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-8 pl-0">
                <div className="modal-header">
                  <h6 className="e-modal-header">Add Category & Sub Category</h6>
                </div>
              </div>
              <div className="col-lg-4 col-4">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalClose.bind(this)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 pr-0">
                <div className="">

                  {
                    this.state.categoryImage ?
                      <div className="e-invitation-wrap e-upload-image" >
                        <span className="e-request" onClick={() => this.setState({ categoryImage: null })}></span>
                        <div style={{backgroundImage:`url(${this.state.categoryImage})`}} className="e-image-thumb"></div>
                      </div>
                      :
                      <>
                        {
                          this.state.categoryImageLoading ?
                            <span className="e-upload-btn e-upload-loader"></span>
                          :
                            <span className="e-upload-btn d-flex align-items-end justify-content-center pb-1" style={{fontSize:"8px", color: " #F16522ee"}} onClick={this.triggerClick.bind(this, "#category-image-upload")}>500 X 500</span>
                        }
                      </>
                  }

                  <ImageUploader id={"category-image-upload"} accept={".jpg,.png,.jpeg,.gif"}
                  uploadSuccess={(tempVal) => this.setState({ categoryImage: tempVal, categoryImageLoading: false })}
                  uploadStart={() => this.setState({ categoryImageLoading: true })}/>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="pt-3">
                  <InputBox id="categoryName" type="text" label="Category Name" valuechange={(name) => this.setState({categoryName: name})}/>
                </div>
              </div>
              <div className="col-lg-2 e-priority-label">
                <div className="pt-4 mt-1">
                  <SelectBox placeholder="0" options={this.props.options}   selectchange={(tempVal) => this.setState({order: tempVal.value})}/>
                  <label>Priority</label>
                </div>
              </div>
            </div>
            <div className="row pt-2">
                <div className="col-lg-12">
                  <div className="e-border-bottom"></div>
                </div>
            </div>

            <>
            {
              this.state.subCategory.map(subCat =>(
                <div className="row pt-3">
              <div className="col-lg-2 pr-0">
                <div className="">
                {
                    subCat.image_url ?
                      <div className="e-invitation-wrap e-upload-image" >
                        <span className="e-request" onClick={this.removeImage.bind(this, this.state.subCategory.indexOf(subCat))}></span>
                        <div style={{backgroundImage:`url(${subCat.image_url})`}} className="e-image-thumb"></div>
                      </div>
                      :
                      <>
                        {
                          this.state.subCatImageLoading[this.state.subCategory.indexOf(subCat)] ?
                            <span className="e-upload-btn e-upload-loader"></span>
                          :
                            <span className="e-upload-btn d-flex align-items-end justify-content-center pb-1" style={{fontSize:"8px", color: " #F16522ee"}} onClick={this.triggerClick.bind(this, `#Subcategory-${this.state.subCategory.indexOf(subCat)}-image-upload`)}>500 x 500</span>
                        }
                      </>
                  }

                  <ImageUploader id={`Subcategory-${this.state.subCategory.indexOf(subCat)}-image-upload`} accept={".jpg,.png,.jpeg,.gif"}
                  uploadSuccess={(tempVal) => this.imageUploaded(tempVal, this.state.subCategory.indexOf(subCat), this)}
                  uploadStart={this.imageUploading.bind(this, this.state.subCategory.indexOf(subCat))}/>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="pt-3">
                  <dl className="ev-input-content-wrap e-m-bottom-20">
                    <dd className="ev-input-contentbox ev-small-input-contentbox">
                      <input id={`Subcategory-${this.state.subCategory.indexOf(subCat)}-input`} value={subCat.name} type="text" placeholder={this.props.placeholder} onChange={this.addName.bind(this, this.state.subCategory.indexOf(subCat))} required/>
                      <label htmlFor={`Subcategory-${this.state.subCategory.indexOf(subCat)}-input`} className="label">Sub Category Name</label>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="col-lg-1 px-0">
                  <div className="pt-3 d-flex align-items-center" style={{height:" 65px"}} >
                    <span className="e-delete" onClick={this.deleteSubCat.bind(this, this.state.subCategory.indexOf(subCat))}></span>
                  </div>
              </div>
              </div>
              ))
            }
            </>

            <div className="row">
              <div className="pl-2">
                <TextButton  className="e-t-btn" name="+ Add Sub Category" handleClick={this.addSubCat.bind(this)}/>
              </div>
            </div>

            <div className="modal-footer pr-0">
            { this.state.error.length ? <div className="col-lg-12"><div className="e-error mb-3">{this.state.error}</div></div> : null }
              {
                this.state.apiLoading?
                  <div className="col-lg-12">
                  <div className="float-right">
                        <ButtonLoader/>
                      </div>
                  </div>
                :
                  <React.Fragment>
                    <div className="col-lg-12">
                      {
                        this.state.categoryName.length && this.state.categoryImage?
                          <PrimaryButton className="e-p-btn e-login-btn float-right"  name="Save Now" handleClick={this.addCategory.bind(this)} />
                        :
                          <PrimaryButton disabled={true} className="e-p-btn e-login-btn float-right e-inActive"  name="Save Now"/>
                      }
                    </div>
                  </React.Fragment>

              }
            </div>
          </div>
        </div>
        :
        <></>
        }
      </div>
    );
  }
}

export default CategoryModal;
