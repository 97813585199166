/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import InputBox from '../../components/formComponents/inputBox.js';
import SelectBox from '../../components/formComponents/selectBox';
import ButtonLoader from '../../components/loaders/buttonLoader.js';


/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/hooks/post';
import PhoneValidation from '../../services/validation/phone';

class ForgotPin extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      error: "",
      setPassword: false,
      mobile: null,
      cities: [],
      city: null
    }
  }

  async generateOtp() {
    this.setState({apiLoading: true});
    let tempValid = await PhoneValidation(this.state.mobile);
    if(tempValid){
      let url = devconfig.admin + "/auth/sent-otp";
      let data = JSON.stringify({
        "mobile" : `+91${this.state.mobile}`
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.props.login();
          this.setState({apiLoading: false});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this))
    }
    else{
      this.setState({ error: "Enter a valid mobile number", apiLoading: false});
    }
    
  }


  render(){

    return (
      <React.Fragment>
        <div className="">
          <h4 className="e-card-p-txt">Forgot PIN</h4>
        </div>
        <div className="pt-3 e-forgot-pin">
        <span className="pt-2 e-edit-modal"><p>Enter your registered Mobile number to generate an OTP, which can be used as a Login PIN</p></span>
          <dl className="ev-input-content-wrap mb-4 mt-2">
            <dd className="ev-input-contentbox ev-small-input-contentbox">
            <InputBox 
                required={true} 
                placeholder="Mobile Number"
                id="e-mobile" 
                type="number" 
                value={this.state.mobile}
                prefix="+91"
                valuechange={(number) => this.setState({mobile: number})}  />
            </dd>
          </dl>
        </div>

        { this.state.error.length ? <div className="e-error">{this.state.error}</div> : null }

        <div className="float-right mb-1 pt-2 d-flex align-items-center">
          {
            this.state.apiLoading ?
              <ButtonLoader/>
            :
              <React.Fragment>
                <div className="pr-3"><TextButton name={<span className="e-left-arrow pl-3">Back to Login</span>} handleClick={()=>this.props.login()}/></div>
                {
                  this.state.mobile  ?
                    <PrimaryButton className="e-p-btn e-login-btn" name="Get OTP" handleClick={this.generateOtp.bind(this)}/>
                  :
                    <PrimaryButton disabled={true} className="e-p-btn e-login-btn e-disable" name="Get OTP"/>
                }
              </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }

}

export default ForgotPin;
