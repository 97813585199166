/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/

/*Assets*/
import logo from '../../assets/images/SA-logo.png';
import logoCollapse from '../../assets/images/SA-logo.png';
import menu from '../../assets/images/icons/menu.svg';
import close from '../../assets/images/icons/close.svg'

// import profile from '../../assets/images/profile-placeholder.jpg';
import $ from 'jquery';



class Sidebar extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeItem: "",
      sidebarCollape: false,
      filterActive: false,
    }
  }

  componentWillMount() {
    this.findActiveItem();


  }

  async findActiveItem(){
    setTimeout(() => {
      var tempUrl = [];
      tempUrl = window.location.href.split("/");
      this.setState({activeItem: tempUrl[tempUrl.length-1]});
    }, 500);
  }

  navItemClick(item){
    this.setState({activeItem: item});
  }

  sidebarCollape(item){
    this.props.collapse(!this.state.sidebarCollape);
    this.setState({sidebarCollape: !this.state.sidebarCollape});
  }

  logout(){
    localStorage.clear();
    sessionStorage.clear();
    window.location = "/";
  }
   menuClick(){
    this.setState({filterActive: !this.state.filterActive});
  }
   dDownToggleMob(){
    // this.setState({dDownToggleMob: !this.state.dDownToggleMob});
    this.props.sidebarCollapse();
  }


  render() {
    return (
      <React.Fragment>

        <div className="e-sidebar">
          <Link onClick={this.navItemClick.bind(this, "location")} to="/location">
          <div className="e-sidebar-logo px-4 pt-4 pb-3">
            {
              !this.state.sidebarCollape ?
                <img src={logo} alt="ninja truck logo"/>
              :
                <img src={logoCollapse} alt="ninja truck logo"/>
            }

          </div>
          </Link>
          {
            !this.state.sidebarCollape ?
              <div className="e-sidebar-list-wrap">
                <span className="e-menu-collape-icon" onClick={this.sidebarCollape.bind(this)}></span>
                {/* <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "dashboard" ?
                      <Link className="e-sidebar-home e-active" onClick={this.navItemClick.bind(this, "dashboard")} to="/dashboard">Dashboard</Link>
                    :
                      <Link className="e-sidebar-home" onClick={this.navItemClick.bind(this, "dashboard")} to="/dashboard">Dashboard</Link>
                  }
                </div> */}
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "location" ?
                      <Link className="e-sidebar-location e-active" onClick={this.navItemClick.bind(this, "location")} to="/location">Location</Link>
                    :
                      <Link className="e-sidebar-location" onClick={this.navItemClick.bind(this, "location")} to="/location">Location</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "branch-admins" ?
                      <Link className="e-sidebar-branch-admins e-active" onClick={this.navItemClick.bind(this, "branch-admins")} to="/branch-admins">Branch Admins</Link>
                    :
                      <Link className="e-sidebar-branch-admins" onClick={this.navItemClick.bind(this, "branch-admins")} to="/branch-admins">Branch Admins</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "categories" ?
                      <Link className="e-sidebar-categories e-active" onClick={this.navItemClick.bind(this, "categories")} to="/categories">Categories</Link>
                    :
                      <Link className="e-sidebar-categories" onClick={this.navItemClick.bind(this, "categories")} to="/categories">Categories</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "brand" ?
                      <Link className="e-sidebar-brand e-active" onClick={this.navItemClick.bind(this, "brand")} to="/brand">Brands</Link>
                    :
                      <Link className="e-sidebar-brand" onClick={this.navItemClick.bind(this, "brand")} to="/brand">Brands</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "profile" ?
                      <Link className="e-sidebar-branch-admins e-active" onClick={this.navItemClick.bind(this, "profile")} to="/profile">Profile</Link>
                    :
                      <Link className="e-sidebar-branch-admins" onClick={this.navItemClick.bind(this, "profile")} to="/profile">Profile</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  <Link className="e-sidebar-logout" onClick={this.logout}>Logout</Link>
                </div>


              </div>
            :
              <div className="e-sidebar-list-wrap e-collaspse-nav-wrap">
                <span className="e-menu-collape-icon e-closed" onClick={this.sidebarCollape.bind(this)}></span>
                {/* <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "dashboard" ?
                      <Link className="e-sidebar-home e-active" onClick={this.navItemClick.bind(this, "dashboard")} to="/dashboard">Dashboard</Link>
                    :
                      <Link className="e-sidebar-home" onClick={this.navItemClick.bind(this, "dashboard")} to="/dashboard">Dashboard</Link>
                  }
                </div> */}
                <div className="e-sidebar-item">
                    {
                      this.state.activeItem === "location" ?
                        <Link className="e-sidebar-location e-active" onClick={this.navItemClick.bind(this, "location")} to="/location">Location</Link>
                      :
                        <Link className="e-sidebar-location" onClick={this.navItemClick.bind(this, "location")} to="/location">Location</Link>
                    }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "branch-admins" ?
                      <Link className="e-sidebar-branch-admins e-active" onClick={this.navItemClick.bind(this, "branch-admins")} to="/branch-admins">Branch Admins</Link>
                    :
                      <Link className="e-sidebar-branch-admins" onClick={this.navItemClick.bind(this, "branch-admins")} to="/branch-admins">Branch Admins</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "categories" ?
                      <Link className="e-sidebar-categories e-active" onClick={this.navItemClick.bind(this, "categories")} to="/categories">Categories</Link>
                    :
                      <Link className="e-sidebar-categories" onClick={this.navItemClick.bind(this, "categories")} to="/categories">Categories</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  {
                    this.state.activeItem === "brand" ?
                      <Link className="e-sidebar-brand e-active" onClick={this.navItemClick.bind(this, "brand")} to="/brand">Brands</Link>
                    :
                      <Link className="e-sidebar-brand" onClick={this.navItemClick.bind(this, "brand")} to="/brand">Brands</Link>
                  }
                </div>
                <div className="e-sidebar-item">
                  <Link className="e-sidebar-logout" onClick={this.logout}>Logout</Link>
                </div>
              </div>
          }

        </div>

         <div className="e-mob-header-show d-lg-none">
          <div className="row p-3">
              <div className="col-md-4 col-sm-4 col-4">
                <Link to="/">
                  <img className="mr-5 e-logo" src={logo} alt="Tradeschool logo"/>
                </Link>
              </div>
              <div className="col-md-8 col-sm-8 col-8 text-right">
                {
                  this.props.dDownToggleMob ?
                    <img className="e-menu-icon e-close" src={close} onClick={this.dDownToggleMob.bind(this)} alt=""/>
                  :
                    <img className="e-menu-icon" src={menu} onClick={this.dDownToggleMob.bind(this)} alt=""/>
                }
              </div>
            </div>

            {
              this.props.dDownToggleMob ?
                <div className="row">
                  <div className="col-lg-12">
                    <div className="e-mob-menu-wrap">

                     <ul className="e-main-navitem-wrap">

                        <li className="nav-item pt-3">
                          <div className="e-sidebar-item">
                            {
                              this.state.activeItem === "location" ?
                                <Link className="e-sidebar-location e-active" onClick={()=>{this.dDownToggleMob(); this.navItemClick("location");}} to="/location">Location</Link>
                              :
                                <Link className="e-sidebar-location" onClick={()=>{this.dDownToggleMob(); this.navItemClick("location");}} to="/location">Location</Link>
                            }
                        </div>
                        </li>

                        <li>
                          <div className="e-sidebar-item">
                            {
                              this.state.activeItem === "branch-admins" ?
                                <Link className="e-sidebar-branch-admins e-active" onClick={()=>{this.dDownToggleMob(); this.navItemClick("branch-admins");}} to="/branch-admins">Branch Admins</Link>
                              :
                                <Link className="e-sidebar-branch-admins" onClick={()=>{this.dDownToggleMob(); this.navItemClick("branch-admins");}} to="/branch-admins">Branch Admins</Link>
                            }
                          </div>
                        </li>
                        <li>
                          <div className="e-sidebar-item">
                            {
                              this.state.activeItem === "categories" ?
                                <Link className="e-sidebar-categories e-active" onClick={()=>{this.dDownToggleMob(); this.navItemClick("categories");}} to="/categories">Categories</Link>
                              :
                                <Link className="e-sidebar-categories" onClick={()=>{this.dDownToggleMob(); this.navItemClick("categories");}} to="/categories">Categories</Link>
                            }
                          </div>
                        </li>
                        <li>
                         <div className="e-sidebar-item">
                          {
                            this.state.activeItem === "brand" ?
                              <Link className="e-sidebar-brand e-active" onClick={()=>{this.dDownToggleMob(); this.navItemClick("brand");}} to="/brand">Brands</Link>
                            :
                              <Link className="e-sidebar-brand" onClick={()=>{this.dDownToggleMob(); this.navItemClick("brand");}} to="/brand">Brands</Link>
                          }
                        </div>
                        </li>


                        <li>
                          <div className="e-sidebar-item">
                            <Link className="e-sidebar-logout" onClick={this.logout}>Logout</Link>
                          </div>
                        </li>


                      </ul>


                    </div>
                  </div>
                </div>
              :
                null
            }

        </div>
      </React.Fragment>


    );
  }
}

export default Sidebar;
