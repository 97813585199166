/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class InputBox extends Component {
  constructor(props){
    super(props);
    this.state = {
    	value: ""
    }
  }

  componentDidMount(){
    if(this.props.defaultValue){
      this.setState({value: this.props.defaultValue});
    }
  }

  valueChange(e) {
    this.setState({value: e.target.value});
    this.props.valuechange(e.target.value);
  }

  render() {
    return (
      <dl className="ev-input-content-wrap e-m-bottom-20">
        <dd className={this.props.prefix ? "ev-input-contentbox ev-small-input-contentbox e-prefix-added" : "ev-input-contentbox ev-small-input-contentbox"}>
        {
            this.props.prefix &&
            <span className="e-prefix">{this.props.prefix}</span>
          }
          <input id={this.props.id} value={this.state.value} type={this.props.type} placeholder={this.props.placeholder} onChange={this.valueChange.bind(this)} required/>
          <label htmlFor={this.props.id} className="label">{this.props.label}</label>
        </dd>
      </dl>
    );
  }
}

export default InputBox;
