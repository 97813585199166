
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
// import {redirect,useState} from 'React';
import DataTable from 'react-data-table-component';
import PrimaryButton from '../../components/buttons/primaryButton';
import Detail from '../../container/admin/detail';

class AdminTable extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }
  render() {

    const columns = [
  {
    name: 'Branch Admin Name',
    selector: 'name',
    sortable: true,
    minWidth: "145px",
    cell: row => <span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.name}</span>
  },
  {
    name: 'Phone',
    selector: 'mobile',
    sortable: false,
    right: false,
    minWidth: "145px",
    cell: row => <Link to="/detail" className="e-link"><span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.mobile}</span></Link>
  },
  {
    name: 'City Name',
    selector: 'city_name',
    sortable: true,
    right: false,
    minWidth: "120px",
    cell: row => <span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.city_name}</span>
  },
  {
    name: 'Shops Managed',
    selector: 'no_of_shops',
    sortable: true,
    right: false,
    minWidth: "100px",
    cell: row => <span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.no_of_shops}</span>
  },
  {
    name: 'Added on',
    selector: 'created_date',
    sortable: true,
    right: false,
    minWidth: "100px",
    cell: row => <span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.created}</span>
  },
  {
    name: 'Actions',
    selector: 'action',
    sortable: false,
    right: true,
    button: true,
    minWidth: "190px",

  },
];

    return (
       <DataTable
          columns={columns}
          data={this.props.data}
          onRowClicked={rowData => {
            window.location="/detail"
          }}
          pagination
          paginationPerPage={8}
        />
        );
  }
}
export default AdminTable;
