/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import Header from '../../components/navigations/header.js';
import PrimaryButton from '../../components/buttons/primaryButton';
import TextButton from '../../components/buttons/textButton';
import InputBox from '../../components/formComponents/inputBox.js';
import ButtonLoader from '../../components/loaders/buttonLoader.js';
import ForgotPin from './forgotPin';
// Services
import devconfig from '../../config/config.js';

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      phone: "",
      pin: "",
      apiLoading: false,
      error: ""
    }
  }
  // let history = useHistory();

  loginAction= () => {

    this.setState({apiLoading: true});

    var tempEncodeData = `+91${this.state.phone}:${this.state.pin}`;
    var encodedData = btoa(tempEncodeData);
    $.ajax({
      type: "GET",
      url: devconfig.admin + '/auth/login',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Basic " + encodedData,
      }
    })
    .then(function (response) {
      if(response.status === "ok"){
        localStorage.setItem("goodoor_admin", response.access_token);
        localStorage.setItem("goodoor_admin_data", JSON.stringify(response.data));
        window.location = '/location';
      }
      else{
        this.setState({error: response.message, apiLoading: false});
      }
    }.bind(this))
    .catch(function(response){
      this.setState({error: response.responseJSON.message, apiLoading: false});
    }.bind(this))
    }
    render(){

      return (
        <div className="container-fluid e-login-outer-wrap pl-0">
          <Header/>
          
           <div className="offset-md-7 col-md-4 e-bg-white e-login-panel position-absolute col-10 offset-1">

              {
          this.state.forgotPin?
          <ForgotPin login={()=>this.setState({forgotPin: false})} cities={this.state.cities}/>
          :
          <React.Fragment>
              <div className="">
                <h4 className="e-card-p-txt">Admin Login</h4>
              </div>
              <div className="pt-3">
                <dl className="ev-input-content-wrap mb-4">
                  <dd className="ev-input-contentbox ev-small-input-contentbox">
                    <InputBox placeholder="Phone Number" valuechange={(number) => this.setState({phone: number})} prefix="+91"  required/>
                  </dd>
                </dl>
                <dl className="ev-input-content-wrap">
                  <dd className="ev-input-contentbox ev-small-input-contentbox">
                    <InputBox placeholder="6 digit PIN" type="password" valuechange={(pinNumber) => this.setState({pin: pinNumber})}  required/>
                  </dd>
                </dl>
              </div>
              <div className="row pt-3 pb-2">
              <div className="col-lg-12">
              
              { this.state.error.length ? <div className="e-error mb-3">{this.state.error}</div> : null }
              <div className="float-right mb-1 pt-3 d-flex align-items-center">
              <div className="pr-3"><TextButton name="Forgot PIN?" handleClick={()=>this.setState({forgotPin: true})}/></div>
                  {
                      this.state.apiLoading?
                      <div className="float-right">
                        <ButtonLoader/>
                      </div>
                      :
                      <>
                        {
                            this.state.phone.length && this.state.pin.length ?
                                <PrimaryButton className="e-p-btn e-login-btn float-right" name="Login Now" handleClick={this.loginAction.bind(this)}/>
                            :
                              <PrimaryButton disabled={true} className="e-p-btn e-login-btn float-right e-inActive" name="Login Now"/>
                        }
                      </>
                  }
                  </div>
                </div>
              </div>
              </React.Fragment>
            }
            </div>
        </div>
      );
  }

}

export default Login;
