
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import PrimaryButton from '../../components/buttons/primaryButton';

// const data = [
//               { id: 1, city_name: 'Thodupuzha', delivery_location: 'Karimkunnam','last_edited': '20 May 2000','action': 'sample' },
//               { id: 2, city_name: 'Pala', delivery_location: 'Karimkunnam','last_edited': '20 May 2000','action': 'sample' },
//               { id: 3, city_name: 'Pkm', delivery_location: 'Karimkunnam','last_edited': '20 May 2000','action':  <PrimaryButton className="e-p-btn e-login-btn" name="Action"/> }
//             ];

const columns = [
  {
    name: 'City Name',
    selector: 'city_name',
    sortable: true,
    cell: row => <span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.city_name}</span>
  },
  {
    name: 'Delivery Locations',
    selector: 'delivery_location',
    sortable: false,
    right: false,
    minWidth: "700px !important;",
  },
  {
    name: 'Last Edited on',
    selector: 'last_edited',
    sortable: false,
    right: false,
    cell: row => <span className={row.active? "e-location-span d-flex align-items-center py-1": "e-text-disabled e-location-span d-flex align-items-center py-1"}>{row.last_edited}</span>
  },
  {
    name: 'Actions',
    selector: 'action',
    sortable: false,
    right: false,
    button: true

  },
];

class MyTable extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <DataTable
        columns={columns}
        data={this.props.data}
      />
    )
  }
};
export default MyTable;
