import React, { Component }  from 'react';
import ButtonLoader from '../../components/loaders/buttonLoader.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/hooks/post';

class ToggleSwitch extends Component{
  constructor(props){
    super(props);
    this.state = {
        checked: false,
        apiLoading: false,
        error: false
    }
  }

  componentDidMount() {
    this.setState({ checked: this.props.defaultChecked});
  }

  onChecked(){
    this.setState({apiLoading: true, error: null});
    var url = devconfig.admin + "/brands/toggle-active";
    var data = JSON.stringify({
      "brand_id": this.props.id
    })
    APIPost(url, data).then(function(response){
        if(response.status === "ok"){
            this.props.reload();
            this.setState({apiLoading: false});
          }
          else{
            this.setState({ error: response.message, apiLoading: false});
          }
        }.bind(this))

  }

  render() {
    return(
      <>
      {
          this.state.apiLoading?
            <ButtonLoader/>
          :
            <label className="switch">
                <input type="checkbox" onClick={this.onChecked.bind(this)} defaultChecked={this.props.active}/>
                <span className={this.props.active ? "slider round e-switch-active" : "slider round e-switch-inactive"}></span>
            </label>
      }
      </>
    );
  }
}

export default ToggleSwitch;